$base03:    #002b36;
$base02:    #073642;
$base01:    #586e75;
$base00:    #657b83;
$base0:     #839496;
$base1:     #93a1a1;
$base2:     #eee8d5;
$base3:     #fdf6e3;
$yellow:    #b58900;
$orange:    #cb4b16;
$red:       #dc322f;
$magenta:   #d33682;
$violet:    #6c71c4;
$blue:      #268bd2;
$cyan:      #2aa198;
$green:     #859900;


.foh__order-details {
  .order-item-todos {
    &.pane {
      background: #bdecff;
      padding: 0;
      overflow: hidden;
    }
    &.complete {
      filter: grayscale(100%);
      .todo-list-header {
        background: none;
        color: $base03;
      }
    }
    .todo-list-header {
      // text-align: center;
      font-weight: 600;
      padding: 0.3em;
      background: $red;
      color: white;

    }
    .todo-item {
      padding: 0.3em;
    }
  }
}
