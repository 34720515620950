$base03:    #002b36;
$base02:    #073642;
$base01:    #586e75;
$base00:    #657b83;
$base0:     #839496;
$base1:     #93a1a1;
$base2:     #eee8d5;
$base3:     #fdf6e3;
$yellow:    #b58900;
$orange:    #cb4b16;
$red:       #dc322f;
$magenta:   #d33682;
$violet:    #6c71c4;
$blue:      #268bd2;
$cyan:      #2aa198;
$green:     #859900;

.order {

  padding: 0em 0.2em;
  margin: 0.2em 0.2em;
  border: 2px solid black;
  border-radius: 6px;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.5);
  background: white;
  /* border: 1px solid black; */
  /* border-bottom: 2px solid black; */
  .order-item-todos {
    &.pane {
      border: 2px solid gray;
      border-radius: 0.5em;
      background: #bdecff;
      padding: 0;
      overflow: hidden;
    }
    &.complete {
      filter: grayscale(100%);
      .todo-list-header {
        background: none;
        color: $base03;
      }
    }
    .todo-list-header {
      // text-align: center;
      font-weight: 600;
      padding: 0.3em;
      background: $red;
      color: white;

    }
    .todo-item {
      padding: 0.3em;
    }
  }
}
.order.test-order {
  /* text-decoration: line-through; */
  /* text-decoration-color: red; */
}
.order.scheduled {
  /* border-top: 8px green solid; */
  /* border-bottom: 8px green solid; */
  /* border-left:  */
}
.order-number {
  margin: 0.5em 0;
}
.order-number__number {
  font-weight: 600;
}
.customer-info {
  margin: 0.5em 0;
}
.order-items-list {

  /* padding: 0.5em 0 0 0.5em; */
  /* margin-left: 0.5em; */
  /* list-style: decimal; */
}
.order-item {
  /* display: list-item; */
  /* border-left: 0.2em solid #009a00; */
  border-bottom: 2px solid rgba(0,0,0,0.3);

  display: flex;
  align-items: flex-start;
}
.order-item .legacy-quantity {
  display: block;
  background: blue;
  padding: 0.5em;
  border-radius: 5px;
  color: white;
  font-weight: 600;
  margin: 0 0.2em;
}
.order-item:nth-of-type(2n+1) {
  background: #eee;
}

.order-item-details {
  /* font-weight: 600; */
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.price {
  font-size: 0.8em;
  flex: 1;
  text-align: right;
}
/* .order-item-details > * {
  margin-right: 0.2em;
} */
.note {
  font-style: italic;
  /* font-size: 1.1em; */
  /* border-left: 0.1em solid #38db00; */
  text-decoration: underline;
  /* padding-left: 0.1em */
}
.actions {
  margin: 0.5em 0;
}

.category {
  font-weight: 600;
  color: #697178;
  /* text-transform: lowercase; */
  font-size: 0.8em;
  /* background: #cbc6d2; */
}
.category-and-name {
  display: flex;
  flex-direction: column;
}

.thumbnail {
  width: 75px;
  margin-right: 0.1em;
}
.thumbnail > img {
  width: 100%;
  border-radius: 5px;
}
.text {
  flex: 1;
}

.filters {
  display: flex;

}

.delivery-info {
  font-size: 0.8em;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  grid-column-gap: 0.2em;
}
.attr-name {

}

.order-totals {
  font-size: 0.7em;
  justify-content: end;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.15em 1em;
}
.label {
  grid-column: 1/2;

}
.divider {
  grid-column: 1/3;
  border-bottom: 2px dashed black;
}
.grand-total {
  /* border-top: 2px solid black; */
  font-size: 1.2em;
  font-weight: 600;
}
.cart-type {
  display: inline-block;
  /* background: yellow; */
  margin-right: 0.1em;
  padding: 0 0.2em;
  border-radius: 0.3em;
}

/* Scheduled */
.scheduled-text {
  background: #eee;
  /* padding: 0 0.2em; */
}
.warning-text {
  color: white;
  font-weight: 600;
  background: #d10000;
  border-radius: 0.2em;
  padding: 0 0.2em;
}

.legacy-actions {
  text-align: right;
}
