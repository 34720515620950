// .new-order-alert {
//   position: fixed;
//   width: 100%;
//   top: 0.5em;
//   box-sizing: border-box;
//   padding: 0.5em;
//   // margin: 0.5em;


// }

.new-order-alert__content {
  border-bottom: 2px solid black;
  font-size: 0.8em;
  padding: 0.4em 0;
  background: whitesmoke;
  // box-shadow: 0px 5px 20px gray;
  &.new-order {
    animation: flash 2s infinite;
  }
  // min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .alert-text {
    // line-height: 1em;
    padding: 0;
    margin: 0;
    // display: inline-block;
    // width: 100px;
    // padding: 1em; 
    // background: white;
    
    // border-radius: 0.3em;
    // border: 2px solid black;
    &.no-notifications {
      font-style: italic;
      color: gray;
    }
    li {
      text-decoration: underline;
      font-size: 1.5em;
    }
  }
}

@keyframes flash {
  0% {
    backgound: white;
  }
  50% {
    background: yellow;
  }
  0% {
    backgound: white;
  }
}