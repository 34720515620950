

.modgroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.1em
}


.group-name {
  /* font-weight: 600; */
  /* color: #697178; */
  /* text-transform: lowercase; */
  font-size: 0.6em;
  line-height: 0.7em;
  text-transform: lowercase;
  text-decoration: underline;
}

.modifier:after {
  content: ', ';
  color: green;
}
.modifier {
  display: flex;
  align-items: flex-start;
}
.modifier-price {
  font-size: 0.5em;
  font-weight: 600;
  line-height: 1.3em;
  /* background: green; */
  /* padding: 0 0.1em; */
  /* border-radius: 0.3em; */
  color: red;
}