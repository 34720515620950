.order-rep.status {
  &.done:before {
    content: "✅"
  }
  .tasks {
    display: flex;


  }
  .task {
    border: 1px solid gray;
    border-radius: 0.4em;
    display: inline-block;
    margin: 0em 0.2em;
    overflow: hidden;
  }
  .task-name {
    font-weight: 600;
    background: #4762ff;
    color: white;
    display: inline-block;
    padding: 0.1em 0.2em;
    box-sizing: border-box;
    border-right: 1px solid gray;
    // background: rgba(123,123,123,0.5);
  }
  .task-status {
    padding: 0.1em 0.2em;
    display: inline-block;
  }
}
