@import '../Styles/vars';

.orders-nav {
  font-family: $fontExtraCondensed;
  background-color: #fdf6e3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='119' height='119' viewBox='0 0 100 100'%3E%3Cg stroke='%2393a1a1' stroke-width='0' %3E%3Crect fill='%23eee8d5' x='-60' y='-60' width='110' height='240'/%3E%3C/g%3E%3C/svg%3E");
  .section-header {
    background: $orange;
    color: $base3;
    border-top: 2px solid $base02;
    border-left: none;
    border-right: none;
    text-align: center;
    button {
      text-decoration: underline;
      color: $base3;
      display: block;
      border: none;
      font: inherit;
      background: $blue;
      width: 100%;
    }
  }
}

a.orders-nav__item {
  overflow: hidden;
  background: $base2;
  color: $base03;
  padding: 0.2em 0;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid $base03;
  line-height: 1em;
  // &.incomplete {
  //   border-left: #ffc612;
  // }
  &.active {
  //  font-weight: 800;
   background: $base1;
   // color: $base3;
  }
  .name {
    text-align: center;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-left: 0.1em;
  }
  .complete-icon {
    font-size: 0.8em;
    line-height: 0.9em;
  }
  .status {
    text-align: center;
    font-size: 0.7em;
    &.complete {
      // color: $green;
      // font-weight: 600
    }
  }
  .date {
    text-align: center;
  }
  .time {
    text-align: center;
    font-size: 0.8em;
  }
  .new-order-label {
    text-align: center;
    .text {
      display: inline-block;
      background: green;
      border-radius: 5px;
      color: white;
      padding: 0 0.4em;
    }
  }
  .need-action-order-label {
    text-align: center;
    .text {
      display: inline-block;
      background: #ffb300;
      border-radius: 5px;
      color: white;
      padding: 0 0.4em;
    }
  }
  .need-action-order-label {

  }
}
