.order-ready-estimate-editor {
  // background: #c7d9d9;
  .button-group {
    display: flex;
    align-items: stretch;
  }
  button {
    font-size: 1.2em;
    width: 2em;
    border: none;
    background: #bbf7e4;
  }
  input {
    width: 2em;
    text-align: center;
    border: none;
    background: #ecf6e0;
  }
}
