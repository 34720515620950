$base03:    #002b36;
$base02:    #073642;
$base01:    #586e75;
$base00:    #657b83;
$base0:     #839496;
$base1:     #93a1a1;
$base2:     #eee8d5;
$base3:     #fdf6e3;
$yellow:    #b58900;
$orange:    #cb4b16;
$red:       #dc322f;
$magenta:   #d33682;
$violet:    #6c71c4;
$blue:      #268bd2;
$cyan:      #2aa198;
$green:     #859900;



.foh-order {
  .analytics.pane {
    padding: 1em 0.5em;
    background: $base03;
    color: $base2;
    .attr {
      display: inline-block;
      width: 5em;
      // font-weight: 600;
    }
    h3 {
      font-weight: 600;
    }
  }
  // filter: blur(2px);
  background-color: #fdf6e3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='396' height='396' viewBox='0 0 100 100'%3E%3Cg stroke='%2393a1a1' stroke-width='0' %3E%3Crect fill='%23eee8d5' x='-60' y='-60' width='110' height='240'/%3E%3C/g%3E%3C/svg%3E");
  color: $base02;
  height: 100%;
  padding-bottom: 200px;
  .app-controls {
    text-align: center;
  }
  .print-button {
    width: 10em;
  }
  .show-older-items-action {

    padding: 1em;
    // background: blue;
    font-weight: 600;
  }
  .pane {
    background: $base3;
    margin: 0.3em 0.2em;
    line-height: 1.25em;
    // background: #f1f2f3;
    padding: 0.2em;
    border-radius: 5px;
    box-shadow: 0px 2px 8px $base01
  }
  .order-type {

    .token {
      padding: 0.2em;
      // background: $blue;
      // color: white;
      font-weight: 600;
      border-radius: 0.3em;
      margin-right: 0.3em;
      display: inline-block;
    }
  }
  .top-controls {
    display: flex;
    flex-wrap: wrap;
    .right {
      flex: 1;
      button {
        margin-right: 1em;
      }
    }
  }
  .scheduled-text {
    background: none;
    // display: flex;
    // align-items: baseline;
    // flex-wrap: wrap;
    .bp3-icon-time {
      position: relative;
      top: -0.3em;
      margin-right: 0.2em;
    }
  }
  .customer-info {
    .basic-info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .tip {
      background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
      padding: 0.1em 0.3em;
      border-radius: 0.5rem;
      margin-right: 0.3em;
      font-weight: 600;
    }
  }

  .delivery-info {
    font-size: 1em;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
    grid-column-gap: 0.2em;
  }
  .attr-name {
    font-weight: 600;
  }
  .items-group {
    margin: 0.5em 0;
    .items-group-name {
      font-size: 1em;
      font-weight: 600;
      color: $violet;
      &:before {
        content: "❖ "
      }
      // font-style: italic;
      // margin-left: 1.5em;
      // text-align: center;
    }
    .items-group-name-text {
      // background-color: #002b36;
      // color: #fdf6e3;
    }
    .group-items {
      // border-left: 4px solid $base0
      margin-left: 3px;
    }
  }
  
  .order-item {
    align-items: flex-start;
    border-bottom: none !important;
    > .text {
      border-bottom: 2px solid $base0;
    }

  }
  .order-item:nth-of-type(2n+1) {
    background: none;

  }
  .quantity {
    margin-right: 0.1em;
    .text {
      border-radius: 0.1em;
      display: inline-block;
      background: $red;
      color: $base2;
      padding: 0 0.3em;
      font-weight: 600;
      

    }
    &.single-quantity {
      .text {
        background: none;
        font-weight: 400;
        // background: $base2;
        color: $base03;
      }
    }
  }
  .order-item-details {
    .name {
      font-weight: 600;
      
    }
    
  }
  .utensils-option {
    font-weight: 600;
    font-size: 1.2em;
    padding: 0.3em;
  }
  .category {
    color: $base01;
    font-weight: 600;
    font-size: 0.9em;
    /* background: #cbc6d2; */
  }
  .modgroup {
    margin-left: 0.5em;
    .items- {
      // margin-left: -0.2em;
      text-transform: none;
      color: $magenta;
      font-size: 0.8em;
    }
  }
  .order-totals {
    font-size: 0.9em;
    justify-content: end;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.15em 1em;
  }
  .payment {
    font-size: 0.9em;
    /* background: #ccff66; */
    /* color: white; */
    padding: 0.2em 0.3em;
    text-align: right;
  }

  .order-actions {
    display: flex;
    flex-wrap: wrap;
    button {
      margin-right: 0.5em;
    }
  }

}

.night {
  img {
    filter: brightness(0.5);
  }
  .foh-order {
    background: $base01;
    color: $base2;
    height: 100vh;
    .pane {
      background: $base02;
    }
    .order-item {
        // border-bottom: 2px solid $base0;
    }
  }
}
