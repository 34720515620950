.modal-ios-audio-enabler {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 1.3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1em;
  background: rgba(0,0,0,0.5);
  text-align: center;
}
.modal-ios-audio-enabler .instructions {
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  background: #bdc4b5;
}
.modal-ios-audio-enabler .alarm {
  width: 1em;
  animation: hithere 2.5s ease infinite;
}
.modal-ios-audio-enabler .yes-no {
  margin-top: 1em;
}
.modal-ios-audio-enabler .yes-no button {
  padding: 0.5em 1em;
}
@-moz-keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%, 60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  70% {
    transform: scale(1);
  }
}
@-webkit-keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%, 60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  70% {
    transform: scale(1);
  }
}
@-o-keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%, 60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  70% {
    transform: scale(1);
  }
}
@keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%, 60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  70% {
    transform: scale(1);
  }
}
