.settings {
  font-size: 0.8em;
  margin: 1em 0.5em;
  display: flex;
  flex-wrap: wrap;
}
.settings-nav {
  width: 100%;
}
.settings-nav-link {
  display: inline-block;
  font-weight: 600;
  padding: 0.3em 0.4em;
  text-decoration: underline;
  text-transform: uppercase;
  &.active {
    background: #bada55;

    color: white;
  }
}


.settings h2 {
  width: 100%;
  /* border-bottom: 2px solid black */
  font-weight: 900;
  text-transform: uppercase;
  color: white;
  padding: 0.1em 0.3em;
  background: #000000;

}
.settings p {

  width: 100%;
  // max-width: 600px;
  margin: 1em auto;
}
.settings .input-group {
  flex: 1;
  margin: 0.5em 0em;
  margin-right: 0.2em;
  background: rgba(0,0,0,0.1);
  padding: 0.3em;
}
.settings label {
  font-weight: 600;
  display: block;
  text-decoration: underline;
}

input:disabled {
  border: none;
}

input {
  font: inherit;
  border: 1px solid black;
}
.snap-array-editor {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.snap-array-item {
  /* border: 1px solid black; */
  background: rgba(0,0,0,0.1);
  margin: 0.5em 0;
  margin-right: 0.2em;
  flex: 1;
  /* margin-left: 0.5em; */
}
.new-item-button {
  width: 100%;
  padding: 0.5em 0;
  font: inherit;
  background: #66ccff;
  color: white;
}
.snap-schedule-editor select {
  font: inherit;
  margin: 0 0.2em;
}
.day-of-week {
  width: 3em;
}

.day-of-week-editor {
  display: flex;
  flex-wrap: wrap;
  margin: 0.2em 0;
  /* background: 1px solid rgba(0,0,0,0.3); */
}
.time-range-editor {
  display: flex;
  flex-wrap: wrap;
}

.sync-error {
  max-width: 600px;
  padding: 1em;
  background: pink;
}

.order-item-transition-enter {
  opacity: 0;
  z-index: 1;
  // transform: scale(0.5);
  &.order-item-transition-enter-active {
    // transform: scale(1);
    opacity: 1;
    transition: all 250ms ease-in;
  }
}

.order-item-transition-leave {
  opacity: 1;
  z-index: 0;
  // transform: scale(1);
  &.order-item-transition-leave-active {
    // transform: scale(0);
    opacity: 0;
    transition: all 250ms ease-in;

  }
}
