@import '../Styles/vars.scss';

$navWidth: 125px;
$miniNavWidth: 60px;
$miniNavWidthBreakpoint: 460px;

.pos-foh-screen {
  // display: flex;
  height: 100%;
  // transform: translate3d(0, 0, 0)
  // height: inherit;
}

.top-bar {
  z-index: 1000;
  position: sticky;
  top: 0;
}

.orders-nav {
  position: fixed;
  left: 0;
  // top: 0;
  height: 100%;
  padding-bottom: 10em;
  box-sizing: border-box;
  border: 1px solid gray;
  overflow-y: scroll;
  width: $navWidth;
  @media screen and (max-width: $miniNavWidthBreakpoint) {
    width: $miniNavWidth;
  }
}


.right-screen {
  margin-left: $navWidth;
  @media screen and (max-width: $miniNavWidthBreakpoint) {
    margin-left: $miniNavWidth;
    font-family: $fontCondensed;
  }
}




@media screen and (max-width: $miniNavWidthBreakpoint) {
  .thumbnail {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .orders-nav {
    width: 200px;
  }
  .right-screen {
    margin-left: 200px;
  }
}
